import request from '../utils/fetch';
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'

export function useAxiosDebounce({ path, params, cb, key = '_id' }, dependencies, shouldCancel, delay = 200) {
  // State and setters for debounced value
  const [data, setData] = useState(null)
  const handler = useRef(null)

  useEffect(
    () => {
      // Update debounced value after delay
      if (shouldCancel) return
      if (handler.current) clearTimeout(handler.current);

      handler.current = setTimeout(() => {
        request({ url: path, params })
          .then((response) => {
            const dataResponse = Array.isArray(response) ? response : [];
            var merged = _.merge(_.keyBy(dataResponse, key), _.keyBy(data, key));
            var mergedData = _.values(merged);

            setData(mergedData);
            if(cb) {
              cb(mergedData);
            }
          }).catch((e) => {
            console.error(e);
          });
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler.current)
      }
    },
    // eslint-disable-next-line
    [...dependencies, shouldCancel, delay] // Only re-call effect if value or delay changes
  )

  return { data, setData }
}
