import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import queryString from 'query-string';
import {
  ArrowDownTrayIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ArrowUpOnSquareIcon,
  PencilIcon,
  PrinterIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Polygon from '@arcgis/core/geometry/Polygon';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import { project } from '@arcgis/core/geometry/projection';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import useEsriMap from '../../hooks/esriMap/useEsriMap';
import RootItemContext from '../../context/RootItemContext';
import createPdf from '../../reports/functions/createPdf';
import MapSearch from '../map/MapSearch';
import request from '../../utils/fetch';

const apiKey = 'AAPTxy8BH1VEsoebNVZXo8HurPULMBokAdvq6kbizvgxkGnMhCyJUsaN_ofaAuuvuYXckfIAZylnA2g8KdLcS51EN67fUPwBpmrEAbl_-rwJF1m8l7LFZWZnBsEazeOU20T05XfZa9NDBeW-T-EciMD1MDDwaeqFODpFu0vib9MBwpqL5Mkpn-WCXrv-rkdwK85PxC_zFUIABT0eu-s8FY2-ERc9Nna46edvRDcr8c1x0pQ.AT1_JWuSZvXB';

// defineMapElements(window, {
//   resourcesUrl: 'https://js.arcgis.com/map-components/4.30/assets',
// });

const formatCoordinates = (coords) => {
  const newCoords = [];
  if (coords.length === 1) {
    newCoords.push(coords[0].length === 1 ? coords[0][0] : coords[0]);
  } else {
    coords.forEach((item) => {
      newCoords.push(item.length > 1 ? [item] : item);
    });
  }
  return newCoords;
};

const defaultLayersShown = [
  'Certified Acres',
  'Non-Certified Acres',
  'Dryland',
  'Surface Water Only',
  'irrWells',
  'irrWellReds',
  'irrWellGreens',
  'irrWellSelects',
  'monWells',
  'monWellReds',
  'monWellGreens',
  'monWellSelects',
  'miWells',
  'wells',
  'flowmeters',
  'chemigations',
  'soils',
  'plss',
  'roads',
];

const EsriMap = (props) => {
  const {
    height = 'h-full',
    layerSwitch,
    layersOverride = defaultLayersShown,
    expandable,
    enableEditor,
    printable = false,
    searchable,
    editingEndpoint,
    canSaveGeoJSON,
    ...restProps
  } = props;
  const { refresh: refreshItem } = useContext(RootItemContext);

  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showLayersController, setShowLayersController] = useState(false);
  const [importGeometry, setImportGeometry] = useState([]);

  const [shownLayers, setShownLayers] = useState(
    layerSwitch && localStorage.getItem('layersOverride')
      ? JSON.parse(localStorage.getItem('layersOverride'))
      : layersOverride.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
  );

  const editingPolygon = editingEndpoint === 'certs'
    || editingEndpoint === 'retirements'
    || printable;

  const {
    certGeo, wellsGeo, flowmetersGeo, chemigationsGeo, retirementGeo,
  } = restProps;

  const handleEditorSubmit = useCallback(async (event) => {
    const { graphics, aborted } = event.detail;
    if (aborted) {
      let geometry = {};

      let id = null;

      if (editingEndpoint === 'certs') {
        id = certGeo?.properties?.id;
        const coordinates = [...certGeo.geometry.coordinates];
        await Promise.all(
          graphics.map(async ({ geometry, attributes }) => {
            if (attributes?.displayId && attributes?.ObjectID) {
              coordinates.splice(attributes.ObjectID, 1);
            } else {
              // Check if the geometry needs to be projected
              if (geometry.spatialReference.wkid !== 4326) {
                // Project the geometry to WGS84 (latitude/longitude)
                const geographicGeometry = await project(
                  geometry,
                  SpatialReference.WGS84,
                );
                coordinates.push(geographicGeometry.rings);
              } else {
                // If it's already in WGS84, just return the rings
                coordinates.push(geometry.rings);
              }
            }
          }),
        );
        if (!coordinates.length) {
          geometry = null;
        } else {
          geometry.coordinates = formatCoordinates(coordinates);
          geometry.type = editingPolygon
            ? coordinates.length > 1
              ? 'MultiPolygon'
              : 'Polygon'
            : 'Point';
        }
      } else {
        switch (editingEndpoint) {
          case 'wells':
            id = wellsGeo?.properties?.id;
            break;
          case 'flowmeters':
            id = flowmetersGeo?.properties?.id;
            break;
          case 'chemigations':
            id = chemigationsGeo?.properties?.id;
            break;
          default:
            id = retirementGeo?.properties?.id;
            break;
        }

        if (
          graphics?.[0]?.attributes?.displayId
          && graphics?.[0]?.attributes?.id
        ) {
          geometry = null;
        } else {
          geometry.type = 'Point';
          geometry.coordinates = [
            graphics[0].geometry.longitude,
            graphics[0].geometry.latitude,
          ];
        }
      }

      // const endpoint2 = editTarget === 'irrigated' ? 'irrigatedGeom' : 'geom';
      const endpoint2 = 'geom';

      await request({
        method: 'post',
        url: `${editingEndpoint}/${id}/${endpoint2}`,
        data: { geometry },
      });
      refreshItem();
      setExpanded(false);
      setEditing((x) => !x);
    }
  }, []);

  const {
    mapDiv, mapView, mapLoaded, sketchGraphicLayerRef, mapRef,
  } = useEsriMap({
    ...restProps,
    shownLayers,
    printable,
    handleEditorSubmit,
    enableEditor,
    editing,
  });

  useEffect(() => {
    if (importGeometry && importGeometry.length && mapRef.current) {
      const features = importGeometry
        .map((cf, index) => {
          const polygons = cf.coordinates.map((coord) => ({
            geometry: new Polygon({
              rings: coord,
            }),
            attributes: {
              ObjectID: index, // Unique identifier for each feature
              type: 'imports',
            },
          }));

          return polygons;
        })
        .flat();

      const importLayer = new FeatureLayer({
        id: 'imports',
        source: features,
        renderer: {
          type: 'simple',
          symbol: {
            type: 'simple-fill', // autocasts as new SimpleFillSymbol()
            color: [219, 171, 245, 0.4],
            outline: {
              color: '#af53e0',
              width: 5,
            },
          },
        },
        objectIdField: 'ObjectID', // Field for unique ObjectID
        fields: [
          {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid',
          },
        ],
        minScale: 72224,
      });
      mapRef.current.add(importLayer);
    }
  }, [importGeometry, mapRef]);

  function handleExpandedChange() {
    const map = mapView.current;
    setExpanded((x) => !x);

    setTimeout(() => {
      // map.resize();
      let { zoom } = mapView;
      if (expanded) zoom -= 2.75;
      else zoom += 2.75;
      map.zoom = zoom;
    });
  }

  async function printMap() {
    const screenshot = await mapView.current.takeScreenshot();
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      content: [
        {
          image: screenshot.dataUrl,
          width: 600,
          margin: [80, 0],
        },
      ],
      defaultStyle: {
        fontSize: 11,
        font: 'Arial',
      },
    };
    createPdf(docDefinition);
  }

  async function handleLayerSwitchChange(key) {
    // const map = mapRef.current;
    let layersToFlip = [];
    switch (key) {
      case 'plss':
        layersToFlip = ['Sections', 'Townships'];
        break;
      case 'roads':
        layersToFlip = [
          'highways',
          'highways_label',
          'streetCenterlines',
          'streetCenterlines_label',
        ];
        break;
      default:
    }
    layersToFlip.forEach(async (layer) => {
      const sectionsLayer = await mapView.current?.map?.findLayerById(layer);
      if (sectionsLayer) {
        sectionsLayer.visible = !shownLayers[key];
      }
    });

    // for wells and FMs, visibility is modified by changing pointGeom

    const newShownLayers = { ...shownLayers, [key]: !shownLayers[key] };
    setShownLayers(newShownLayers);
    localStorage.setItem('layersOverride', JSON.stringify(newShownLayers));
    if (window.location.pathname === '/map') {
      const { x, y, z } = queryString.parse(window.location.search);
      const ls = Object.entries(newShownLayers)
        .filter((entry) => entry[1])
        .map(([key]) => key)
        .join(',');
      window.history.pushState(
        {},
        '',
        `?${queryString.stringify({
          x,
          y,
          z,
          ls,
        })}`,
      );
    }
  }

  async function handleSave() {
    const { items } = sketchGraphicLayerRef?.current?.graphics;

    const featuresEmpty = !items.length;

    const geometry = featuresEmpty
      ? null
      : {
        type: editingPolygon
          ? items.length > 1
            ? 'MultiPolygon'
            : 'Polygon'
          : 'Point',
      };
    if (!featuresEmpty) {
      const coordinates = [];
      await Promise.all(
        items.map(async ({ geometry }) => {
          if (geometry.spatialReference.wkid !== 4326) {
            const geographicGeometry = await project(
              geometry,
              SpatialReference.WGS84,
            );
            coordinates.push(geographicGeometry.rings);
          } else {
            coordinates.push(geometry.rings);
          }
        }),
      );
      geometry.coordinates = items.length > 1 ? coordinates : coordinates[0];
    }

    if (printable) {
      if (geometry) {
        const { default: exportGeoJSON } = await import(
          '../../utils/exportGeoJSON'
        );
        exportGeoJSON(geometry, {}, 'Map');
      }
    }
  }

  async function importGeo() {
    const [{ default: fileUpload }, { default: parseGeoJSON }] = await Promise.all([
      import('../../utils/fileUpload'),
      import('../../utils/parseGeoJSON'),
    ]);

    fileUpload('geojson', async (result) => {
      const geometry = parseGeoJSON(result);

      if (!geometry) return;

      setImportGeometry([...importGeometry, geometry]);
    });
  }

  return (
    <div
      className={`GenericMap leaflet-control-container leaflet-touch ${
        expanded ? ' expanded' : ''
      }`}
    >
      <div className={`mapDiv w-full ${height}`} ref={mapDiv} />
      <div className="absolute top-2 left-2 flex flex-col">
        {searchable && <MapSearch mapRef={mapView} />}
        {layerSwitch && (
          <div className="leaflet-left" style={{ zIndex: '999' }}>
            <div
              className={`leaflet-control-layers leaflet-control ${
                mapLoaded && showLayersController
                  ? 'leaflet-control-layers-expanded'
                  : ''
              }`}
              style={{ marginTop: '10px', marginLeft: '0' }}
              onMouseEnter={() => setShowLayersController(true)}
              onMouseLeave={() => setShowLayersController(false)}
            >
              <div className="leaflet-control-layers-toggle" title="Layers" />
              <form className="leaflet-control-layers-list">
                <div className="leaflet-control-layers-overlays">
                  {[
                    {
                      key: 'certs_group',
                      label: 'Tracts',
                      child: [
                        { key: 'Certified Acres', label: 'Certified Acres' },
                        {
                          key: 'Non-Certified Acres',
                          label: 'Non-Certified Acres',
                        },
                        { key: 'Dryland', label: 'Dryland' }, // Municipal and Industrial
                        {
                          key: 'Surface Water Only',
                          label: 'Surface Water Only',
                        },
                      ],
                    },
                    {
                      key: 'wells_group',
                      label: 'Wells',
                      child: [
                        { key: 'irrWells', label: 'Irrigation Wells' },
                        { key: 'monWells', label: 'Monitoring Wells' },
                        { key: 'miWells', label: 'M&I Wells' }, // Municipal and Industrial
                        { key: 'wells', label: 'Other Wells' },
                      ],
                    },
                    { key: 'flowmeters', label: 'Flowmeters' },
                    { key: 'chemigations', label: 'Chemigations' },
                    { key: 'soils', label: 'Soil Samples' },
                    { key: 'plss', label: 'PLSS' },
                  ].map(({ key, label, child }) => (
                    <div
                      className="leaflet-control-layers-group"
                      key={`group_${key}`}
                    >
                      {child && (
                        <label className="leaflet-control-layers-group-label">
                          <span className="leaflet-control-layers-group-name">
                            {label}
                          </span>
                        </label>
                      )}
                      {child
                        && child.map((e) => (
                          <label
                            className="leaflet-control-layers-label leaflet-control-layers-child"
                            key={`group_${e.key}`}
                          >
                            <input
                              className="leaflet-control-layers-selector"
                              id={`layer_switch_${e.key}`}
                              key={e.key}
                              label={e.label}
                              type="checkbox"
                              checked={shownLayers[e.key]}
                              onChange={() => handleLayerSwitchChange(e.key)}
                            />
                            <span>{e.label}</span>
                          </label>
                        ))}
                      {!child && (
                        <label className="leaflet-control-layers-label">
                          <input
                            className="leaflet-control-layers-selector"
                            id={`layer_switch_${key}`}
                            key={key}
                            label={label}
                            type="checkbox"
                            checked={shownLayers[key]}
                            onChange={() => handleLayerSwitchChange(key)}
                          />
                          <span>{label}</span>
                        </label>
                      )}
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="mt-2">
          {expandable && (
            <button
              type="button"
              className="h-8 min-h-8 w-8 bg-white flex items-center justify-center"
              onClick={handleExpandedChange}
            >
              {expanded ? (
                <ArrowsPointingInIcon className="h-4 w-4" aria-hidden="true" />
              ) : (
                <ArrowsPointingOutIcon className="h-4 w-4" aria-hidden="true" />
              )}
            </button>
          )}
          {canSaveGeoJSON && (
            <button
              type="button"
              className="h-8 min-h-8 w-8 bg-white flex items-center justify-center border-t border-map-border"
              onClick={handleSave}
            >
              <ArrowDownTrayIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          )}
          {enableEditor && (
            <button
              type="button"
              className="h-8 min-h-8 w-8 bg-white flex items-center justify-center border-t border-map-border"
              onClick={() => setEditing((x) => !x)}
            >
              {editing ? (
                <XMarkIcon className="h-4 w-4" aria-hidden="true" />
              ) : (
                <PencilIcon className="h-4 w-4" aria-hidden="true" />
              )}
            </button>
          )}
          {printable && (
            <>
              <button
                type="button"
                className="h-8 min-h-8 w-8 bg-white flex items-center justify-center border-t border-map-border"
                onClick={printMap}
              >
                <PrinterIcon className="h-4 w-4" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="h-8 min-h-8 w-8 bg-white flex items-center justify-center border-t border-map-border"
                onClick={importGeo}
              >
                <ArrowUpOnSquareIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EsriMap;
