import { useCallback } from 'react';
import usePrevious from '../usePrevious';
import { VIEW_PORT_DECIMAL } from '../../utils/constants';

const useEsriViewportOverride = ({ viewport }) => {
  const {
    zoom, latitude, longitude, dist,
  } = viewport || {};
  const prevLong = usePrevious(Number(longitude).toFixed(VIEW_PORT_DECIMAL));
  const prevLat = usePrevious(Number(latitude).toFixed(VIEW_PORT_DECIMAL));
  const prevDist = usePrevious(Number(dist).toFixed(VIEW_PORT_DECIMAL));
  const prevZoom = usePrevious(Number(zoom).toFixed(VIEW_PORT_DECIMAL));

  const params = {
    byGeom: true,
    lat: latitude,
    long: longitude,
    dist,
  };

  const shouldInvalid = useCallback(
    (minZoomLevel = 13, maxZoomLevel) => {
      if (!zoom || !latitude || !longitude || !dist) return true;

      if (
        Number(longitude).toFixed(VIEW_PORT_DECIMAL) === prevLong
        && Number(latitude).toFixed(VIEW_PORT_DECIMAL) === prevLat
        && Number(dist).toFixed(VIEW_PORT_DECIMAL) === prevDist
        && Number(zoom).toFixed(VIEW_PORT_DECIMAL) > prevZoom
      ) {
        return true;
      }

      if (zoom < minZoomLevel) return true;

      if (maxZoomLevel && zoom > maxZoomLevel) return true;

      return false;
    },
    [zoom, prevZoom, longitude, latitude, dist, prevLong, prevLat, prevDist],
  );

  return {
    params,
    shouldInvalid,
  };
};

export default useEsriViewportOverride;