import { useMemo } from 'react';

function getIconType(key, data) {
  switch (key) {
    case 'wells':
    case 'irrWells':
    case 'monWells':
    case 'miWells':
      switch (data?.Well_Type) {
        case 'Irrigation':
          switch (data?.Color) {
            case 'red':
              return 'irrWellRed';
            case 'green':
              return 'irrWellGreen';
            case 'yellow':
              return 'irrWellSelect';
            default:
              return 'irrWell';
          }
        case 'Monitoring (Quality)':
          switch (data?.Color) {
            case 'red':
              return 'monWellRed';
            case 'green':
              return 'monWellGreen';
            case 'yellow':
              return 'monWellSelect';
            default:
              return 'monWell';
          }
        case 'Municipal':
        case 'Commercial/Industrial':
          return 'miWell';
        default:
          switch (data?.Color) {
            case 'red':
              return 'irrWellRed';
            case 'green':
              return 'irrWellGreen';
            case 'yellow':
              return 'irrWellSelect';
            default:
              return 'well';
          }
      }
    case 'flowmeters':
      if (data?.hasCurrentRead) return 'fmGreen';
      return 'fmRed';
    case 'chemigations':
      return 'chemigation';
    case 'soils':
      return 'soil';
    default:
      throw new Error('Invalid key');
  }
}

// combines wells, FMs and chemigations (for clustering)
export default function useCombinedPointGeom({
  wellsGeo, flowmetersGeo, chemigationsGeo, soilsGeo, shownLayers,
}) {
  const pointGeom = useMemo(() => {
    const features = [];
    [
      { key: 'irrWells', type: 'Well', data: wellsGeo },
      { key: 'monWells', type: 'Well', data: wellsGeo },
      { key: 'miWells', type: 'Well', data: wellsGeo },
      { key: 'wells', type: 'Well', data: wellsGeo },
      { key: 'flowmeters', type: 'Flowmeter', data: flowmetersGeo },
      { key: 'chemigations', type: 'Chemigation', data: chemigationsGeo },
      { key: 'soils', type: 'Soils', data: soilsGeo },
    ].forEach(({ key, type, data }) => {
      if (shownLayers && shownLayers[key] && data) {
        if (data.type === 'FeatureCollection') {
          features.push(...data.features
            .map((feature) => {
              feature.properties.type = type;
              feature.properties.iconType = getIconType(key, feature.properties);
              feature.properties.category = key;
              if(type === 'Well') {
                feature.properties.displayId = feature.properties.Reg_No;
              }

              return feature;
            })
            .filter((feature) => {
              if (type !== 'Well') return true;
              // filter wells into their appropriate layers
              // this is usually done by iconType
              // the exception is that if the well's "primary layer"
              // is hidden but the monWells layer is shown and the well
              // also has Mon_Active=true, then we want to move it into
              // the monWells feature array
              const wellType = `${feature.properties.iconType}s`;
              if (
                !shownLayers[wellType]
                && shownLayers.monWells
                && feature.properties.Mon_Active
              ) {
                return key === 'monWells';
              }
              return key === wellType.replace('Red', '').replace('Green', '').replace('Select', '');
            }));
        } else {
          const iconType = getIconType(key, data.properties);
          if (
            key === 'flowmeters'
                || key === 'chemigations'
                || key === 'soils'
                || key.slice(0, -1) === iconType
          ) {
            features.push({
              ...data,
              properties: {
                ...data.properties,
                type,
                iconType,
                category: key,
              },
            });
          }
        }
      }
    });

    return {
      type: 'FeatureCollection',
      features,
    };
  }, [wellsGeo, flowmetersGeo, chemigationsGeo, soilsGeo, shownLayers]);

  return pointGeom;
}
