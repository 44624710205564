import { useEffect, useRef, useState } from 'react';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import Zoom from '@arcgis/core/widgets/Zoom';
import Sketch from '@arcgis/core/widgets/Sketch';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import queryString from 'query-string';
import Editor from '@arcgis/core/widgets/Editor';
import useAsriMapLayersSetup from './useEsriMapLayersSetup';
import getNrdLocation from '../../config/nrdLocations';
import useCombinedPointGeom from '../map/useCombinedPointGeom';
import { useAxiosDebounce } from '../useDebounce';
import convertToFeatureCollection from '../../utils/convertToFeatureCollection';
import useEsriViewportOverride from './useEsriViewportOverride';
import '@arcgis/core/assets/esri/themes/light/main.css';
import request from '../../utils/fetch';

export default function useEsriMap(props) {
  const mapDiv = useRef(null);
  const mapRef = useRef(null);
  const mapView = useRef(null);
  const graphicsLayerRef = useRef(null);
  const sketchGraphicLayerRef = useRef(null);
  const editorRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const {
    viewportOverride,
    bboxMap,
    bboxPadding = 20,
    wellsGeo,
    flowmetersGeo,
    chemigationsGeo,
    soilsGeo,
    shownLayers,
    viewportCallback,
    editTools = [],
    enableEditor = false,
    handleEditorSubmit,
    editing,
  } = props;

  const [viewport, setViewport] = useState(null);
  const { params, shouldInvalid } = useEsriViewportOverride({
    viewport: viewportCallback ? viewportOverride : viewport,
  });

  const { lat, long, dist } = params;

  const { data: townshipsGeo, setData: setTownshipsGeo } = useAxiosDebounce(
    {
      path: 'v2/layers/townships',
      params,
      key: 'Id',
      cb: (arr) => setTownshipsGeo(
        convertToFeatureCollection(arr, 'Label', ['Id'], 'Townships'),
      ),
    },
    [lat, long, dist],
    !viewportCallback || shouldInvalid(10, 13),
  );

  const { data: sectionsGeo, setData: setSectionsGeo } = useAxiosDebounce(
    {
      path: 'v2/layers/sections',
      params,
      key: 'Id',
      cb: (arr) => setSectionsGeo(
        convertToFeatureCollection(arr, 'Label', ['Id'], 'Sections'),
      ),
    },
    [lat, long, dist],
    shouldInvalid(13),
  );

  const longOverride = viewportOverride?.longitude;
  const latOverride = viewportOverride?.latitude;
  const zoomOverride = viewportOverride?.zoom;

  useEffect(() => {
    if (mapDiv.current) {
      /**
       * Initialize application
       */
      const map = new Map({
        basemap: 'hybrid',
      });
      mapRef.current = map;

      let defaultViewport;
      if (!zoomOverride || !latOverride || !longOverride) {
        defaultViewport = getNrdLocation();
      }

      const view = new MapView({
        container: mapDiv.current, // The id or node representing the DOM element containing the view.
        map, // An instance of a Map object to display in the view.
        center: [
          longOverride || defaultViewport.longitude,
          latOverride || defaultViewport.latitude,
        ],
        zoom: zoomOverride || defaultViewport.zoom,
        ui: {
          components: ['attribution'],
        },
        padding: {
          top: bboxPadding,
          right: bboxPadding,
          bottom: bboxPadding,
          left: bboxPadding,
        },
        ...(bboxMap
          ? {
            extent: {
              xmin: bboxMap[0],
              ymin: bboxMap[1],
              xmax: bboxMap[2],
              ymax: bboxMap[3],
            },
          }
          : {}),
        // scale: 10000000, // Represents the map scale at the center of the view.
      });
      mapView.current = view;

      // Create a new GraphicsLayer and add it to the map
      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);
      graphicsLayerRef.current = graphicsLayer;

      // Add event listeners for drag, zoom, and extent changes
      view.watch('extent', (extent) => {
        const { center, width } = extent;
        const { longitude, latitude } = center;

        if (window.location.pathname === '/map') {
          const { ls } = queryString.parse(window.location.search);
          window.history.pushState({}, '', `?${queryString.stringify({
            x: longitude, y: latitude, z: view.zoom, ls,
          })}`);
        }

        const viewportFunc = viewportCallback || setViewport;
        viewportFunc({
          longitude,
          latitude,
          zoom: view.zoom,
          dist: width,
        });
        // Handle extent change, e.g., fetch new data or update state
      });

      view.when(() => {
        setMapLoaded(true);
        // Perform additional actions after map is loaded
        // e.g., fetch data, display popups, etc.
      });

      if (editTools.length) {
        const sketchLayer = new GraphicsLayer();
        map.add(sketchLayer);

        const sketch = new Sketch({
          view,
          layer: sketchLayer,
          creationMode: 'update',
          availableCreateTools: editTools,
        });

        view.ui.add(sketch, 'top-right'); // Add the Sketch widget to the top-right corner
        sketchGraphicLayerRef.current = sketchLayer;
      }

      if (enableEditor) {
        editorRef.current = new Editor({
          view: mapView.current,
          visible: false,
        });
        editorRef.current.on('sketch-update', handleEditorSubmit);
        view.ui.add(editorRef.current, 'top-right');
      }

      const zoom = new Zoom({
        view,
      });
      view.ui.add(zoom, 'bottom-right');

      return () => view && view.destroy();
    }
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.visible = editing;
    }
  }, [editing, editorRef.current]);

  const pointGeom = useCombinedPointGeom({
    wellsGeo,
    flowmetersGeo,
    chemigationsGeo,
    soilsGeo,
    shownLayers,
  });

  useAsriMapLayersSetup({
    ...props,
    graphicsLayerRef,
    pointGeom,
    mapRef,
    townshipsGeo,
    sectionsGeo,
    viewport,
    mapView,
  });

  return {
    mapDiv,
    mapRef,
    mapView,
    mapLoaded,
    sketchGraphicLayerRef,
    graphicsLayerRef,
  };
}
