import Graphic from '@arcgis/core/Graphic';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import Polygon from '@arcgis/core/geometry/Polygon';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Point from '@arcgis/core/geometry/Point';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import { useEffect } from 'react';
import iconUrls from '../map/iconUrls';
import request from '../../utils/fetch';

function tractBgColor(type) {
  if (type === 'Certified Acres') {
    return [219, 171, 245, 0.4];
  }
  return [0, 255, 0, 0.4];
}

export default function useEsriMapLayersSetup({
  certGeo,
  irrigatedGeo,
  pointGeom,
  shownLayers,
  graphicsLayerRef,
  mapRef,
  alwaysShowIcons,
  labelWellsWithRegNo,
  townshipsGeo,
  sectionsGeo,
}) {
  const renderer = {
    type: 'simple',
    symbol: {
      type: 'simple-fill',
      color: 'rgba(0, 0, 0, 0)',
      outline: {
        color: '#ddd',
        width: 2,
      },
    },
    labelingInfo: [
      {
        symbol: new TextSymbol({
          color: '#eee',
        }),
        labelPlacement: 'always-horizontal',
        labelExpressionInfo: {
          expression: '$feature.displayId',
        },
      },
    ],
  };

  useEffect(() => {
    if (
      sectionsGeo
      && graphicsLayerRef.current
      && mapRef.current
      && shownLayers.plss
    ) {
      const existingLayer = mapRef.current.findLayerById('Sections');
      if (existingLayer) {
        return;
      }

      const features = sectionsGeo.features.map((sec) => {
        const polygon = new Polygon({
          rings: sec.geometry.coordinates[0],
        });

        // Create the polygon graphic
        return {
          geometry: polygon,
          attributes: {
            type: 'sections',
            displayId: sec.properties.displayId || '',
          },
        };
      });

      // Create a feature layer with the polygons
      const sectionLayer = new FeatureLayer({
        id: 'Sections',
        source: features,
        renderer,
        objectIdField: 'ObjectID', // Any unique field, you can generate one if necessary
        editingEnabled: false,
        fields: [
          {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid',
          },
          {
            name: 'displayId',
            alias: 'Display ID',
            type: 'string',
          },
        ],
        labelingInfo: [
          {
            symbol: new TextSymbol({
              color: '#eee',
              font: {
                size: 14,
                family: 'Arial',
              },
            }),
            labelPlacement: 'always-horizontal',
            labelExpressionInfo: {
              expression: '$feature.displayId',
            },
            minScale: 72224, // Visible at zoom level 13 or closer
          },
        ],
        minScale: 72224, // Visible at zoom level 13 or closer
      });

      // Add the feature layer to the map
      mapRef.current.add(sectionLayer);
    }
  }, [graphicsLayerRef, sectionsGeo, renderer, mapRef, shownLayers]);

  useEffect(() => {
    if (
      townshipsGeo
      && graphicsLayerRef.current
      && mapRef.current
      && shownLayers.plss
    ) {
      const existingLayer = mapRef.current.findLayerById('Townships');
      if (existingLayer) {
        return;
      }

      const features = townshipsGeo.features.map((sec, index) => {
        const polygon = new Polygon({
          rings: sec.geometry.coordinates[0],
        });

        // Create the polygon graphic
        return {
          geometry: polygon,
          attributes: {
            ObjectID: index + 1, // Generate a unique ObjectID using the index
            type: 'townships',
            displayId: sec.properties.displayId || '',
          },
        };
      });

      // Create a feature layer with the polygons
      const townshipLayer = new FeatureLayer({
        id: 'Townships',
        source: features,
        renderer, // Ensure your renderer is properly defined
        objectIdField: 'ObjectID', // Use the generated unique ObjectID field
        editingEnabled: false,
        fields: [
          {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid', // Object ID type
          },
          {
            name: 'displayId',
            alias: 'Display ID',
            type: 'string',
          },
        ],
        labelingInfo: [
          {
            symbol: new TextSymbol({
              color: '#eee',
              font: {
                size: 14,
                family: 'Arial',
              },
            }),
            labelPlacement: 'always-horizontal',
            labelExpressionInfo: {
              expression: '$feature.displayId',
            },
            minScale: 288895,
            maxScale: 72224,
          },
        ],
        // Adjust scales to display the layer between zoom levels 10 and 13
        minScale: 577791,
        maxScale: 72224,
      });

      // Add the feature layer to the map
      mapRef.current.add(townshipLayer);
    }
  }, [graphicsLayerRef, townshipsGeo, renderer, mapRef, shownLayers]);

  useEffect(() => {
    if (certGeo && graphicsLayerRef.current) {
      let filteredCertFeatures = [];

      if (certGeo.features && certGeo.features.length) {
        filteredCertFeatures = certGeo.features.filter((data) => (shownLayers ? shownLayers[data.properties.Category] === true : true));
      } else if (certGeo.properties) {
        filteredCertFeatures = shownLayers[certGeo.properties.Category] === true
          ? [{ ...certGeo }]
          : [];
      }

      // Remove existing layers for categories that are no longer shown
      (certGeo.features || []).forEach((feature) => {
        const category = feature.properties.Category;
        if (!shownLayers[category]) {
          const layerToRemove = mapRef.current.findLayerById(
            `certs-${category}`,
          );
          if (layerToRemove) {
            mapRef.current.remove(layerToRemove);
          }
        }
      });

      // Create features for FeatureLayer
      const features = filteredCertFeatures
        .map((cf, index) => {
          const polygons = cf.geometry.coordinates.map((coord, idx) => ({
            geometry: new Polygon({
              rings: coord,
            }),
            attributes: {
              ObjectID: filteredCertFeatures.length === 1 ? idx : index, // Unique identifier for each feature
              type: 'certs',
              displayId: cf.properties.displayId || '',
              category: cf.properties.Category || '',
            },
          }));

          return polygons;
        })
        .flat(); // Flatten in case of multiple coordinates

      // Define the renderer for the layer
      const renderer = new UniqueValueRenderer({
        field: 'category', // Category field to drive the color
        uniqueValueInfos: filteredCertFeatures.map((cf) => ({
          value: cf.properties.Category, // Value of the category
          symbol: new SimpleFillSymbol({
            color: tractBgColor(cf.properties.Category), // Dynamic color
            outline: {
              color: '#af53e0',
              width: 2,
            },
          }),
          label: cf.properties.Category, // Label for the legend, optional
        })),
      });

      // Create a feature layer with the polygons
      const certLayer = new FeatureLayer({
        id: `certs-${filteredCertFeatures[0]?.properties.Category}`, // Unique ID per category
        source: features,
        renderer,
        objectIdField: 'ObjectID', // Field for unique ObjectID
        fields: [
          {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid',
          },
          {
            name: 'displayId',
            alias: 'Display ID',
            type: 'string',
          },
          {
            name: 'category',
            alias: 'Category',
            type: 'string',
          },
        ],
        minScale: 72224,
        labelingInfo: [
          {
            symbol: new TextSymbol({
              color: 'black',
              font: {
                size: 14,
                family: 'Arial',
              },
            }),
            labelPlacement: 'always-horizontal',
            labelExpressionInfo: {
              expression: '$feature.displayId',
            },
            minScale: 18056,
          },
        ],
        popupTemplate: {
          title: 'Tract ID: {displayId}', // Customize title using field attributes
          content: (feature) => {
            const cert = filteredCertFeatures.find(
              (item) => item.properties.displayId
                === feature.graphic.attributes.displayId,
            );
            if (cert?.properties?.id) {
              const div = document.createElement('div');
              div.innerHTML = '<p>Loading...</p>';
              request({ url: `certs/${cert.properties.id}?mapPopup=true` })
                .then((data) => {
                  div.innerHTML = Object.entries(data)
                    .map(([key, val]) => {
                      if (!val || typeof val === 'object' || Array.isArray(val)) {
                        return ''; // Skip invalid or complex data
                      }
                      return `<p><strong>${key.split('_').join(' ')}:</strong> ${val}</p>`;
                    })
                    .join('');
                })
                .catch((error) => {
                // Handle any errors during the fetch
                  console.error('Error fetching data:', error);
                  div.innerHTML = '<p></p>';
                });
              return div;
            }
          },
        },
      });

      // Add the feature layer to the map, only if shownLayers is true for the category
      if (shownLayers[filteredCertFeatures[0]?.properties.Category]) {
        const existingLayer = mapRef.current.findLayerById(
          `certs-${filteredCertFeatures[0]?.properties.Category}`,
        );
        if (!existingLayer) {
          mapRef.current.add(certLayer);
        }
      }
    }
  }, [graphicsLayerRef, certGeo, shownLayers]);

  useEffect(() => {
    if (irrigatedGeo && graphicsLayerRef.current) {
      irrigatedGeo.features.forEach((ig) => {
        // Create the polygon graphic
        const polygonGraphic = new Graphic({
          geometry: {
            type: 'polygon', // autocasts as new Polygon()
            rings: ig.geometry.coordinates,
          },
          symbol: {
            type: 'simple-fill', // autocasts as new SimpleFillSymbol()
            color: [138, 235, 145, 0.4],
            outline: {
              color: '#20d62c',
              width: 2,
            },
          },
          visible: shownLayers.includes('certs'),
          attributes: {
            type: 'irrigated',
          },
        });
        // Add the polygon graphic to the graphics layer
        graphicsLayerRef.current.add(polygonGraphic);
      });
    }
  }, [graphicsLayerRef, irrigatedGeo, shownLayers]);

  useEffect(() => {
    if (pointGeom.features && graphicsLayerRef.current && mapRef.current) {
      // Remove the existing layer if it exists
      const existingLayer = mapRef.current.findLayerById('combined-points');
      if (existingLayer) {
        mapRef.current.remove(existingLayer);
      }

      // Combine all the points from different categories
      const combinedFeatures = pointGeom.features.map((item) => ({
        geometry: new Point({
          longitude: item?.geometry?.coordinates?.[0],
          latitude: item?.geometry?.coordinates?.[1],
        }),
        attributes: item.properties,
      }));
      // Create a single FeatureLayer for all points
      const featureLayer = new FeatureLayer({
        id: 'combined-points',
        source: combinedFeatures,
        objectIdField: 'id',
        fields: [
          { name: 'id', type: 'oid' },
          { name: 'displayId', type: 'string' },
          { name: 'type', type: 'string' },
          { name: 'iconType', type: 'string' },
          { name: 'Reg_No', type: 'string' },
          { name: 'Well_Type', type: 'string' },
          { name: 'category', type: 'string' },
        ],
        renderer: {
          type: 'unique-value',
          field: 'iconType',
          uniqueValueInfos: Object.entries(iconUrls).map(([key, value]) => ({
            value: key,
            symbol: {
              type: 'picture-marker',
              url: value,
              width: 24,
              height: 24,
            },
          })),
        },
        popupTemplate: {
          title: '{Type} ID: {displayId}', // Customize title using field attributes
          content: (feature) => {
            const point = combinedFeatures.find(
              (item) => item.attributes.id === feature.graphic.attributes.id,
            );
            if (point?.attributes?.type === 'Flowmeter') {
              return `
              <div>
                <p><strong>Meter Location:</strong> ${
  point.attributes.Meter_Location || ''
}</p>
                <p><strong>Serial No:</strong> ${
  point.attributes.Serial_num || ''
}</p>
              </div>
            `;
            }
            if (point?.attributes?.type === 'Well') {
              return `
              <div>
                <p><strong>Well Type:</strong> ${
  point.attributes.Well_Type || ''
}</p>
                <p><strong>Reg No</strong> ${point.attributes.Reg_No || ''}</p>
              </div>
            `;
            }
            if (point?.attributes?.type === 'Soils') {
              return `
                  <div>
                    <p><strong>ID:</strong> ${point.attributes._id || ''}</p>
                    <p><strong>Soil Location ID:</strong> ${
  point.attributes.displayId || ''
}</p>
                  </div>
                `;
            }
            if (point?.attributes?.type === 'Chemigation') {
              return `
                  <div>
                    <p><strong>Name:</strong> ${point.attributes.Name || ''}</p>
                  </div>
                `;
            }
            return null;
          },
        },
        ...(alwaysShowIcons && labelWellsWithRegNo
          ? {}
          : {
            featureReduction: {
              type: 'cluster',
              clusterRadius: '40px',
              clusterMinSize: '24px',
              clusterMaxSize: '40px',
              symbol: {
                type: 'simple-marker',
                style: 'circle',
                color: 'rgb(44,169,224)',
                outline: {
                  color: 'rgb(44,169,224)',
                  width: 2,
                },
              },
              labelingInfo: [
                {
                  deconflictionStrategy: 'none',
                  labelExpressionInfo: {
                    expression: "Text($feature.cluster_count, '#,###')",
                  },
                  symbol: {
                    type: 'text',
                    color: '#dddddd',
                    font: {
                      weight: 'bold',
                      family: 'Noto Sans',
                      size: '14px',
                    },
                  },
                  labelPlacement: 'center-center',
                },
              ],
            },
          }),
      });

      // Set the minimum scale for visibility
      featureLayer.minScale = 72224;

      // Add the FeatureLayer to the map
      mapRef.current.add(featureLayer);
    }
  }, [graphicsLayerRef, pointGeom, mapRef, shownLayers]);
}
